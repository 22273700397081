import { forkJoin, map, Observable, of, } from 'rxjs';
import { ApiService } from './api';
import { MetadataApiService } from './metadataApi';
import { INano, nanos } from './nanosData';

const WALLET_API_URL = '1/api/wallets';
const INDEX_API_URL = '1/api/index';
/**
 * Return a message to sign by the user in order to connect the wallet with Playfab
 * @param body 
 * @returns messageToSign
 */
const getWalletConnectSignature = (body: { playFabId: string, wallet: string }): Observable<string> => {
    return ApiService.post(`${ApiService.API_GATEWAY_ENDPOINT}/${WALLET_API_URL}/connect/signature`, body).pipe(
        map((response: { messageToSign: string }) => {
            return response.messageToSign;
        })
    )
}

/**
 * Connect in the backend the wallet and playFabId
 * @param body 
 * @returns string "connected"
 */
const setWalletConnect = (body: { playFabId: string, wallet: string, signedMessage: string }): Observable<{ message: string }> => {
    return ApiService.post(`${ApiService.API_GATEWAY_ENDPOINT}/${WALLET_API_URL}/connect/playfab`, body);
}

/**
 * Get Nanos of a wallet address
 * @param address 
 * @param page 
 * @param pageSize 
 * @param onlyRevealed if set to true, only nanos that had been opened are shown
 * @returns 
 */
const getNanos = (address: string, page = 1, pageSize = 100, onlyRevealed = false) => {
    return forkJoin([ApiService.get(`${ApiService.API_GATEWAY_ENDPOINT}/${WALLET_API_URL}/${address}/nanos?page=${page}&pageSize=${pageSize}`), getAllNanosInfo()]).pipe(
        map((response) => {
            let myNanosResponse = response[0];
            const nanos = response[1];

            if (onlyRevealed) {
                const filteredNanos = myNanosResponse.items.filter((nano) => {
                    return (nano?.metadata?.data);
                });

                myNanosResponse.items = filteredNanos;
            }

            const nanosMerged = myNanosResponse.items.map((nano) => {
                if (nano?.metadata?.data) {
                    for (let nanoIndex in nanos) {
                        const nanoInfo: any = nanos[nanoIndex];
                        let myNanoSlug = MetadataApiService.getNanoSlug(nano);
                        if (nanoInfo.slug === myNanoSlug) {
                            let mergedNano = Object.assign({}, nano, nanoInfo);
                            mergedNano['ai'].enabled = true;
                            return mergedNano;
                        }
                    }
                }
                return Object.assign({}, nano, {
                    ai: { enabled: false }
                });
            });
            myNanosResponse.items = nanosMerged;
            return myNanosResponse;
        })
    );
}

/** return all nano descriptions */
const getAllNanosInfo = (): Observable<Array<INano>> => {
    return of(nanos);
}

/**
 * Get Alpha Passes of a wallet address
 * @param address 
 * @param page 
 * @param pageSize 
 * @returns 
 */
const getAlphaPasses = (address: string, page = 1, pageSize = 100) => {
    return ApiService.get(`${ApiService.API_GATEWAY_ENDPOINT}/${WALLET_API_URL}/${address}/alpha-passes?page=${page}&pageSize=${pageSize}`);
}

/**
 * Return an alpha pass for a wallet account based on id
 * @param address 
 * @param alphaPassId 
 * @returns 
 */
const getAlphaPassById = (address: string, alphaPassId: number) => {
    return ApiService.get(`${ApiService.API_GATEWAY_ENDPOINT}/1/api/wallets/${address}/alpha-passes/${alphaPassId}`);
}

// Reindex passes of wallet
const reindexPassesOfWallet = (address: string) => {
    return ApiService.get(`${ApiService.API_GATEWAY_ENDPOINT}/${INDEX_API_URL}/wallet/${address}`);
}

export const WalletApiService = {
    getWalletConnectSignature,
    setWalletConnect,
    getNanos,
    getAlphaPasses,
    getAlphaPassById,
    reindexPassesOfWallet,
    getAllNanosInfo,
}
