import Navbar from "./Navbar";
import Footer from "./Footer";
import { Box } from "@mui/material";
import CookieConsentBar from "./CookieConsentBar";
import { styled } from '@mui/material/styles';

const Container = styled(Box)`
    position: relative;
`;

const LightBox = styled(Box)`
    position: absolute;
    width: 100%;
    z-index: -1;

    .light {
        width: 204px;
        height: 84px;
        margin: ${props => props.theme.spacing(0, 'auto')};
        background: black; //${props => props.theme.vars.palette.neutral['900']};
        filter: blur(125px);
    }
    &.top {
        top: -46px;
    }

    &.bottom {
        bottom: 140px;
    }
`;

const ContentContainer = styled(Box)`
    min-height: 100vh;
    z-index: 1;
    max-width: ${props => props.theme.maxPageWidth};
    margin: 0 auto;
    overflow: hidden;
    
    /* ${props => props.theme.breakpoints.up("lg")} {
        border-left: 1px solid ${props => props.theme.vars.palette.neutral['800']};
        border-right: 1px solid ${props => props.theme.vars.palette.neutral['800']};
    } */
`;

const Layout = (props: any) => {
    return (
        <Container>
            <Navbar />
            <ContentContainer>
                {props.children}
            </ContentContainer>
            <Footer/>
            <CookieConsentBar />
            <LightBox className="top">
                <Box className="light"/>
            </LightBox>
            <LightBox className="bottom">
                <Box className="light"/>
            </LightBox>
        </Container>
    )
}

export default Layout;
