import {ethers} from "ethers";

/**
 * Is web3 avaiable in the browser
 * @returns 
 */
 export const isWeb3Available = () => {
    if (typeof window !== 'undefined' && typeof window.ethereum !== 'undefined') {
        return Boolean(window.ethereum);
    } else {
        return false;
    }
}

/**
 * Get an account address and validate if valid
 * @param account 
 * @returns boolean
 */
 export const validateAddress = (address: string = ''): boolean => {
    if (!address) { return false; }
    try {
        ethers.utils.getAddress(address);
        return true;
    } catch (e) {
        console.error('Error validating the selected account address');
        return false;
    }
}
