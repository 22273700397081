import { SvgIcon } from "@mui/material";

const WalletIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="m11.525 6.24 2.7-4.152.158-.354-3.035-.698a1.406 1.406 0 0 0-1.685 1.052l-.931 4.153h2.793Zm5.75 7.668c.227.436.347.92.349 1.41a3.081 3.081 0 0 1-1.304 2.505v2.095A3.082 3.082 0 0 1 13.238 23H4.04a3.082 3.082 0 0 1-3.081-3.082V10.72a3.081 3.081 0 0 1 3.081-3.082h9.2a3.082 3.082 0 0 1 3.081 3.082v2.095c.401.283.729.659.956 1.094Zm-2.351 6.01V18.4h-3.902a3.082 3.082 0 0 1 0-6.163h3.902v-1.518a1.685 1.685 0 0 0-1.686-1.685H4.04a1.685 1.685 0 0 0-1.685 1.685v9.2a1.685 1.685 0 0 0 1.685 1.684h9.2a1.685 1.685 0 0 0 1.685-1.685Zm.81-3.408a1.685 1.685 0 0 0-1.192-2.877h-3.52a1.685 1.685 0 1 0 0 3.37h3.52c.447 0 .875-.177 1.191-.493ZM6.673 2.088h1.62l-.93 4.153h-1.77V3.196a1.108 1.108 0 0 1 1.08-1.108Zm12.244 12.198 4.227-6.518a1.862 1.862 0 0 0-.512-2.504L20.24 3.709 19 2.908l-.223-.14-.81-.521a1.788 1.788 0 0 0-2.477.521l-.707 1.1-1.555 2.383a4.516 4.516 0 0 1 4.497 4.497v1.424a4.47 4.47 0 0 1 1.192 2.114Z" /></svg>
        </SvgIcon>
    );
}

export default WalletIcon;