import "../styles/globals.css";
import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { UserContextProvider } from '../providers/UserContext';
import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import theme from '../theme';
import { CacheProvider, EmotionCache } from "@emotion/react";
import Layout from '../components/layout/Layout';
import Head from 'next/head';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import createEmotionCache from '../createEmotionCache';
import { event, usePagesViews } from '../lib/nextjs-google-analytics-gtm';
import { Poppins } from '@next/font/google';
import * as fbq from '../lib/fpixel';
import Script from "next/script";
import StructuredData from "../components/base/StructuredData";
import UIAlert from "../components/base/UIAlert";
import { AlertContextProvider } from "../providers/AlertContext";
// import { chain, configureChains, createClient, WagmiConfig, Chain } from 'wagmi';
// import { EthereumClient, modalConnectors, walletConnectProvider } from '@web3modal/ethereum'
// import { Web3Modal } from '@web3modal/react'
// import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import TagManager from 'react-gtm-module';

const poppins = Poppins({
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  style: 'normal',
  display: 'swap',
  variable: '--poppins-font',
  subsets: ['latin'],
});


export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();

export function reportWebVitals({ id, name, label, value }) {
  event(name, {
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

export default function MyApp({ Component, pageProps, emotionCache = clientSideEmotionCache }: AppPropsWithLayout) {
  useEffect(() => {
    TagManager.initialize({ gtmId: `${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}` });
  }, []);

  usePagesViews();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);
  const router = useRouter();

  const pageJsonData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": "https://www.metananos.com/#organization",
    "name": "META NANOs",
    "url": "https://www.metananos.com",
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "customer service",
      "availableLanguage": "en",
      "email": "contact@nanosmetagames.com"
    },
    "logo": {
      "@type": "ImageObject",
      "@id": "https://www.metananos.com/images/shared/logo.webp/#ImageObject",
      "url": "https://www.metananos.com/images/shared/logo.webp",
      "width": "168",
      "height": "84",
      "caption": "Metananos"
    },
    "sameAs": ["https://discord.gg/dnbRVBNfCb", "https://twitter.com/metananos", "https://instagram.com/metananos", "https://docs.metananos.com"]
  };

  // Facebook pixel
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') { return }
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events]);

  // // 1. Get projectID at https://cloud.walletconnect.com
  // if (!process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID) {
  //   throw new Error('You need to provide NEXT_PUBLIC_PROJECT_ID env variable');
  // }
  // const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;
  // const metananosTestnet: Chain = {
  //   id: Number(process.env.NEXT_PUBLIC_TARGET_CHAIN),
  //   name: `Metananos Testnet`,
  //   network: `Metananos Testnet`,
  //   nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  //   rpcUrls: {
  //     default: `${process.env.NEXT_PUBLIC_WEB3_PROVIDER_HOST}`,
  //   },
  //   testnet: true
  // };

  // // 2. Configure wagmi client
  // const chains = [chain.polygon, metananosTestnet];
  // const { provider } = configureChains(chains, [
  //   walletConnectProvider({ projectId }),
  //   jsonRpcProvider({
  //     rpc: (chain) => ({
  //       http: `${process.env.NEXT_PUBLIC_WEB3_PROVIDER_HOST}`
  //     }),
  //   }),
  // ])
  // const wagmiClient = createClient({
  //   autoConnect: true,
  //   connectors: modalConnectors({ appName: 'web3Modal', chains }),
  //   provider
  // });

  // // 3. Configure modal ethereum client
  // const ethereumClient = new EthereumClient(wagmiClient, chains);

  return getLayout(
    <>
      <style jsx global>{`
        html {
          font-family: ${poppins.style.fontFamily};
        }
      `}</style>
      {process.env.NEXT_PUBLIC_APP_ENV === 'production' &&
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('consent', 'revoke');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
          }}
        />
      }
      {process.env.NEXT_PUBLIC_APP_ENV === 'production' &&
        <Script id="hotjard" strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:3623001,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `
          }}  
        />
      }
      <CacheProvider value={emotionCache}>
        <Head>
          <link rel="icon" href="/favicon.ico" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
          <link rel="manifest" href="/manifest.json" crossOrigin='use-credentials' />
          <meta name="description" content="Join the next level 3D play to earn metaverse with META NANOs!" />
          <meta name="robots" content="index, follow" />
          <meta itemProp="name" content="META NANOs" />
          <meta itemProp="description" content="Join the next level 3D play to earn metaverse with META NANOs!" />
          <meta itemProp="image" content="https://metatags.s3.eu-central-1.amazonaws.com/Twitter_Banner_v2.png" />
          <meta property="og:url" content="https://www.metananos.com" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="META NANOs" />
          <meta property="og:description" content="Join the next level 3D play to earn metaverse with META NANOs!" />
          <meta property="og:image" content="https://metatags.s3.eu-central-1.amazonaws.com/Twitter_Banner_v2.png" />
          <meta name="facebook-domain-verification" content="oa6wypoy3w97b1o46pztpg1zu9a3bn" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="META NANOs" />
          <meta name="twitter:description" content="Join the next level 3D play to earn metaverse with META NANOs!" />
          <meta name="twitter:image" content="https://metatags.s3.eu-central-1.amazonaws.com/Twitter_Banner_v2.png" />
          <link rel="preconnect" href="https://www.googletagmanager.com" />
        </Head>
        {/* <WagmiConfig client={wagmiClient}> */}
          <CssVarsProvider theme={theme}>
            <CssBaseline />
              <UserContextProvider {...pageProps}>
                <AlertContextProvider>
                  <Layout title="META NANOs">
                    <Component {...pageProps} />
                    <UIAlert />
                  </Layout>
                </AlertContextProvider>
              </UserContextProvider>
          </CssVarsProvider>
        {/* </WagmiConfig> */}
      </CacheProvider>
      {/* <Web3Modal
        projectId={projectId}
        theme="light"
        accentColor="purple"
        ethereumClient={ethereumClient}
      /> */}
      <StructuredData id="website-data" data={pageJsonData} isStringify={false} />
    </>
  );
};

