import { experimental_extendTheme as createTheme, } from '@mui/material/styles';

/**
 * Base fontSize: 16px
 * spacing(1.5) => 12
 * spacing(1.16) => 14
 * spacing(2) => 16
 * spacing(2.25) => 18
 * spacing(2.5) => 20
 * spacing(3) => 24
 * spacing(3.75) => 30
 * spacing(5) => 40
 */

const theme = createTheme({
    maxPageWidth: '1536px',
    spacing: 6,
    typography: { 
        allVariants: {
            fontFamily: ['var(--poppins-font)', 'Poppins',  'serif'].join(','),
        },
        fontWeightSemiBold: 600,
    },
    colorSchemes: {
        light: {
            palette: {
                mode: 'dark',
                text: {
                    primary: '#BDBDBD'
                },
                neutral: {
                    main: '#BDBDBD', 
                    900: '#212121', 
                    800: '#424242', 
                    700: '#616161', 
                    600: '#757575', 
                    500: '#9E9E9E', 
                    400: '#BDBDBD',
                    300: '#E0E0E0',
                    200: '#EEEEEE',
                    100: '#F5F5F5',
                    50: '#FAFAFA',
                },
                brand: {
                    700: '#6200EA', 
                    600: '#7C4DFF',  
                    400: '#651FFF', 
                    main: '#7C4DFF',
                    100: '#B388FF' 
                },
                purple: {
                    700: '#AA00FF',
                    main: '#D500F9', 
                    200: '#E040FB',
                    100: '#EA80FC',
                },
                amber: {
                    800: '#FF8F00',
                    600: '#FFB300',
                },
                red: {
                    600: '#E53935',
                    200: '#FF5252',
                },
                green: {
                    600: '#43A047',
                    400: '#66BB6A',
                },
                gray: {
                    600: '#757575',
                    500: '#9E9E9E'
                },
                discord: {
                    main: '#6773F4' 
                },
                twitter: {
                    main: '#2DBAF1'
                }
            },
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600, // down mobile
            md: 900, // down table
            lg: 1200,
            xl: 1536 // desktop
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontFamily: ['var(--poppins-font)', 'Poppins',  'serif'].join(','),
                },
                body: {
                    margin: "0",
                    padding: "0",
                    boxSizing: "border-box",
                    fontFamily: ['var(--poppins-font)', 'Poppins',  'serif'].join(','),
                    backgroundColor: "#212121"
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    color: 'var(--mui-palette-neutral-500)',
                    fontWeight: '600',
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'initial',
                    boxShadow: 'none',
                }
            },
            variants: [
                {
                    props: { variant: 'contained' },
                    style: {
                        background: `var(--mui-palette-brand-100)`,
                        color: 'white',
                        fontWeight: 600,
                        borderRadius: '12px',
                        '&.MuiLoadingButton-root': {
                            background: `var(--mui-palette-brand-100)`,
                            fontWeight: 600,
                            borderRadius: '12px',
                            '.MuiCircularProgress-circle': {
                                color: 'white !important'
                            }
                        },
                        '&:hover, &.Mui-focusVisible': {
                            background: 'var(--mui-palette-brand-main)',
                            boxShadow: 'none',
                        },
                        '&:disabled': {
                            background: 'var(--mui-palette-neutral-600)',
                            color: 'white'
                        },
                        '&.MuiButton-containedSizeSmall': {
                            height: '44px',
                            padding: '12px'
                        },
                        '&.MuiButton-containedSizeMedium': {
                            height: '48px',
                            padding: '12px 18px'
                        },
                        '&.MuiButton-containedSizeLarge': {
                            height: '60px',
                            padding: '12px 18px'
                        },
                        '&.MuiButton-containedDiscord': {
                            background: 'var(--mui-palette-discord-main)'
                        },
                        '&.MuiButton-containedTwitter': {
                            background: 'var(--mui-palette-twitter-main)'
                        }
                    }
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        border: '1px solid var(--mui-palette-brand-100)',
                        color: 'var(--mui-palette-brand-100)',
                        fontWeight: 600,
                        borderRadius: '12px',
                        '&:hover, &.Mui-focusVisible': {
                            borderColor: 'var(--mui-palette-brand-main)',
                            color: 'var(--mui-palette-brand-main)',
                            background: 'none',
                            boxShadow: 'none',
                        },
                        '&:disabled': {
                            borderColor: 'var(--mui-palette-neutral-600)',
                            color: 'var(--mui-palette-neutral-600)',
                            background: 'none',
                        },
                        '&.MuiButton-outlinedSizeSmall': {
                            height: '44px',
                            padding: '12px',
                        },
                        '&.MuiButton-outlinedSizeMedium': {
                            height: '48px',
                            padding: '12px 18px',
                        },
                        '&.MuiButton-outlinedSizeLarge': {
                            height: '60px',
                            padding: '12px 18px',
                        },
                        '&.MuiLoadingButton-root': {
                            borderColor: 'var(--mui-palette-brand-400)',
                            color: 'var(--mui-palette-brand-400)',
                        },
                    }
                },
                {
                    props: { variant: 'text' },
                    style: {
                        border: 'none',
                        color: 'var(--mui-palette-brand-100)',
                        fontWeight: 600,
                        borderRadius: '12px',
                        '&:hover, &.Mui-focusVisible': {
                            borderColor: 'var(--mui-palette-brand-main)',
                            color: 'var(--mui-palette-brand-main)',
                            background: 'none',
                        },
                        '&:disabled': {
                            borderColor: 'var(--mui-palette-neutral-600)',
                            color: 'var(--mui-palette-neutral-600)',
                            background: 'none',
                        },
                        '&.MuiButton-containedSizeSmall': {
                            height: '44px',
                            padding: '12px',
                        },
                        '&.MuiButton-containedSizeMedium': {
                            height: '48px',
                            padding: '12px 18px',
                        },
                        '&.MuiButton-containedSizeLarge': {
                            height: '60px',
                            padding: '12px 18px',
                        },
                        '&.MuiLoadingButton-root': {
                            color: 'var(--mui-palette-brand-400)',
                        }
                    }
                },
            ]
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    background: 'none',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    width: '100%',
                    border: 'red'
                }
            },
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '12px'
                        },
                        '&.focus-visible': {
                            'outlineOffset': 'none !important',
                            'outline': 'none !important',
                        },
                        '& .MuiInputBase-sizeSmall': {
                            height: '44px',
                        },
                        '& label.Mui-focused': {
                            color: 'var(--mui-palette-brand-100)',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor:  "var(--mui-palette-neutral-500)",
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--mui-palette-brand-100)',
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--mui-palette-brand-100)',
                        }
                    }
                }
            ]
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "var(--mui-palette-brand-100)",

                    '&.Mui-checked': {
                        color: "var(--mui-palette-brand-100)",
                    },
                    '.MuiSvgIcon-root': {
                        color: "var(--mui-palette-brand-100)",
                    }
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    color: "var(--mui-palette-neutral-main)",
                }
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    '.MuiCircularProgress-svg': {
                        color: 'var(--mui-palette-brand-main)'
                    }
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPagination-ul': {
                        'justifyContent': 'center'
                    },
                    '.MuiPaginationItem-icon': {
                        'width': '1.875rem',
                        'color': 'var(--mui-palette-brand-main)'
                    }
                }
            }
        },
        MuiSelect: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        'width': '100%',
                        'borderRadius': '12px',

                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--mui-palette-neutral-500)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--mui-palette-brand-100)',
                        },
                        '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--mui-palette-brand-100)',
                        },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--mui-palette-brand-100)',
                        }
                    }
                }
            ]
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    '.MuiMenuItem-root': {
                        'background': 'var(--mui-palette-neutral-800)',
                        'opacity': 1,
                        '&:hover': {
                            'background': 'var(--mui-palette-brand-100)',
                            'color': 'var(--mui-palette-neutral-900)'
                        },
                        '&.Mui-selected': {
                            'background': 'var(--mui-palette-brand-100)',
                            'color': 'var(--mui-palette-neutral-900)',
                            'opacity': 1,
                            '&.Mui-focusVisible': {
                                'background': 'var(--mui-palette-brand-main)',
                                'color': 'white',
                                'opacity': 1
                            }
                        },
                        '&.Mui-disabled': {
                            'opacity': 1
                        },
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: '12px'
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    '.MuiSwitch-switchBase.Mui-checked': {
                        color: 'var(--mui-palette-brand-100)',
                    },

                    '.MuiSwitch-track': {
                        backgroundColor: 'var(--mui-palette-brand-main)'
                    }

                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '.MuiDrawer-paper': {
                        background: 'var(--mui-palette-neutral-900)',
                        padding: '24px 20px 12px 20px',
                    }
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    'borderRadius': '8px',
                    'backgroundColor': 'var(--mui-palette-neutral-700)',
                    '.MuiAlert-message': {
                        color: 'white'
                    },
                    '.MuiSvgIcon-root': {
                        color: 'var(--mui-palette-Alert-errorIconColor)'
                    },
                    '.MuiIconButton-root': {
                        color: 'var(--mui-palette-Alert-errorIconColor)'
                    },
                    '&.MuiAlert-standardSuccess': {
                        '.MuiSvgIcon-root': {
                            color: 'var(--mui-palette-green-400)'
                        }
                    }
                }
            }
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    'backgroundColor': 'var(--mui-palette-neutral-800)',
                    'padding': '6px',
                    'borderRadius': '18px !important',
                    'gap': '6px'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    'border': 'none',
                    'color': 'var(--mui-palette-neutral-500)',
                    '&.Mui-selected': {
                        'background': 'var(--mui-palette-brand-100)',
                        'color': 'white',
                        'borderRadius': '12px !important',
                    },
                    '&:hover': {
                        'background': 'var(--mui-palette-brand-100)',
                        'color': 'white',
                        'borderRadius': '12px !important',
                    }
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    'width': '100%',
                    'background': 'var(--mui-palette-neutral-500)',
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    '.MuiStepLabel-iconContainer': {
                        '&.Mui-completed': {
                            color: 'var(--mui-palette-green-400)',
                        }
                    },
                    '.MuiStepLabel-label': {
                        '&.Mui-completed': {
                            color: 'var(--mui-palette-green-400)',
                        }
                    }
                } 
            } 
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: 'var(--mui-palette-neutral-900)',
                        '.MuiStepIcon-text': {
                            fill: 'var(--mui-palette-brand-100)',
                        }
                    },
                    '&.Mui-completed': {
                        color: 'var(--mui-palette-green-400)',
                    },
                    
                } 
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '.MuiAutocomplete-clearIndicator': {
                        marginRight: '8px'
                    }
                }
            }
        }
    } 
});

/********* Typography  *************/
theme.typography.h1 = {
    fontSize: '3rem',
    lineHeight: '3rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
        fontSize: '2.25rem',
        lineHeight: '2.5rem',
    }
};

theme.typography.h2 = {
    fontSize: '2.25rem',
    lineHeight: '2.5rem',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.875rem',
        lineHeight: '2.25rem',
    },
};

theme.typography.h3 = {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
};

theme.typography.h4 = {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
    },
};

theme.typography.h5 = {
    fontSize: '0.666rem',
    lineHeight: '0.825rem',
    fontWeight: 400,
};

theme.typography.lg = {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: 400,
};

theme.typography.body1 = {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
};

theme.typography.sm = {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
};

theme.typography.xs = {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 400,
};

export default theme;