import { deleteCookie, setCookie, getCookie } from "cookies-next";
import { catchError, delay, forkJoin, from, iif, map, mergeMap, Observable, of, switchMap, tap } from "rxjs";
import { getWalletContextData } from "../blockchain/connect/web3Connect";
import { signMessage$ } from "../blockchain/sign/sign";
import { WalletApiService } from "./walletApi";
import { ApiService } from "./api";
import { withSentry } from "@sentry/nextjs";
export const SESSION_TICKET_COOKIE = 'SessionTicket';
export const ENTITY_TOKEN_COOKIE = 'EntityToken';
export const PLAYER_VERIFIED_COOKIE = 'PlayerVerified';
export const PLAYFAB_ID_COOKIE = 'PlayFabId';

const PLAYFAB_TITLE_ID = process.env.NEXT_PUBLIC_PLAYFAB_ID || '';
const EPIC_GAMES_POSTFIX = `${Math.floor(Math.random() * 1000) + 1}`;
const TEMP_EMAIL_CONFIRMATION = `dev@metananos.com`;
const PLAYFAB_ENDPOINT = `https://${PLAYFAB_TITLE_ID}.playfabapi.com/Client`;
const EPIC_ENDPOINT = `https://api.epicgames.dev/epic`;

export interface IAccountRecoveryEmailRequest {
    TitleId?: string;
    Email: string;
    EmailTemplateId?: string;
}

export interface IAccountRecoveryEmailResponse { }

export interface ILoginWithEmailAddressRequest {
    TitleId?: string;
    Email: string;
    Password: string;
}

export interface ILoginWithEmailAddressResponse extends IFullPlayerResponse {
    EntityToken: {
        EntityToken: string;
        TokenExpiration: string,
        Entity: {
            Id: string;
            Type: string;
            TypeString: string;
        }
    }
    LastLoginTime: string;
    NewlyCreated: boolean;
    PlayFabId: string;
    SessionTicket: string;
}

export interface IRegisterPlayFabUserRequest {
    TitleId?: string;
    Email: string;
    Username: string;
    DisplayName: string;
    Password: string;
}

export interface IRegisterPlayFabUserResponse extends IFullPlayerResponse { }

export interface IAddOrUpdateContactEmailRequest {
    PlayFabId: string;
    EmailAddress: string;
}

export interface IAddOrUpdateContactEmailResponse { }

export interface IGetPlayerProfileRequest {
    PlayFabId?: string; // userId
}

export interface IGetPlayerProfileResponse {
    PlayerProfile: string;
    DisplayName: string;
    PlayerId: string;
    PublisherId: string;
    TitleId: string;
    IsVerified: boolean;
    Email: string;
    ContactEmailAddresses?: Array<{
        EmailAddress: string;
        Name: string;
        VerificationStatus: string;
    }>
    Addresses: Array<string>;
}

export interface IRefreshAuth {
    data: {
        TitleId: string;
        SessionTicket: string;
        EntityToken: {
            Entity: {
                Id: string;
                Type: string;
                TypeString: string;
            },
            EntityToken: string;
            TokenExpiration: string;
        }
    }
}

export interface IGetUserDataResponse {
    Addresses: Array<string>;
}

export interface IFullPlayerResponse extends IGetPlayerProfileResponse, IGetUserDataResponse { }

/**
 * Return playFabId
 * @param request 
 * @returns 
 */
export const GetPlayFabId = () => {
    return getCookie(PLAYFAB_ID_COOKIE);
}

// return the authorization header with the SessionTicket
const GetAuthorizationHeader = (SessionTicket?: string) => {
    return {
        'X-Authorization': SessionTicket ? SessionTicket : getCookie(SESSION_TICKET_COOKIE),
    }
}

// Extract the response.data from the request/response
const GetResponseData = (response) => {
    return response.data;
}

/**
 * Request to PlayFab to sent a recovery email
 * @param request 
 * @returns 
 */
export const SendAccountRecoveryEmail = (request: IAccountRecoveryEmailRequest): Observable<IAccountRecoveryEmailResponse> => {
    return ApiService.post(`${PLAYFAB_ENDPOINT}/SendAccountRecoveryEmail`, {
        ...request,
        TitleId: PLAYFAB_TITLE_ID,
    }).pipe(
        map(GetResponseData),
    )
}

/**
 * Login user in PlayFab
 * @param request 
 * @returns 
 */
export const LoginWithEmailAddress = (request: ILoginWithEmailAddressRequest, rememberMe: boolean = false): Observable<ILoginWithEmailAddressResponse> => {
    return ApiService.post(`${PLAYFAB_ENDPOINT}/LoginWithEmailAddress`, {
        ...request,
        TitleId: PLAYFAB_TITLE_ID,
    }).pipe(
        map(GetResponseData),
        switchMap((loginResponse: ILoginWithEmailAddressResponse) => {
            return GetFullPlayer(loginResponse.PlayFabId, loginResponse.SessionTicket).pipe(
                map((response: IFullPlayerResponse) => {
                    SetPlayFabCookies({
                        rememberMe,
                        entityToken: JSON.stringify(loginResponse.EntityToken),
                        sessionTicket: loginResponse.SessionTicket,
                        playerId: response.PlayerId,
                        isVerified: response.IsVerified,
                    });

                    return {
                        ...loginResponse,
                        ...response
                    }
                })
            )
        })
    ) as any;
}

/**
 * Register user in PlayFab
 * @param request 
 * @returns 
 */
export const RegisterPlayFabUser = (request: IRegisterPlayFabUserRequest): Observable<IRegisterPlayFabUserResponse> => {
    return LogoutPlayer().pipe(
        switchMap(() => {
            return ApiService.post(`${PLAYFAB_ENDPOINT}/RegisterPlayFabUser`, {
                ...request,
                TitleId: PLAYFAB_TITLE_ID,
            });
        }),
        delay(3000),
        map(GetResponseData),
        switchMap((response: IRegisterPlayFabUserResponse) => {
            return LoginWithEmailAddress({
                TitleId: PLAYFAB_TITLE_ID,
                Email: request.Email,
                Password: request.Password,
            });
        }),
        switchMap((loginResponse: ILoginWithEmailAddressResponse) => {
            return AddOrUpdateContactEmail({
                PlayFabId: loginResponse.PlayFabId,
                EmailAddress: request.Email,
            }, loginResponse.SessionTicket).pipe(
                map((response: any) => {
                    return loginResponse;
                })
            )
        }),
        switchMap((loginResponse: ILoginWithEmailAddressResponse) => {
            return GetFullPlayer(loginResponse.PlayFabId, loginResponse.SessionTicket);
        })
    );
}

/**
 * Register user in PlayFab
 * @param request 
 * @returns 
 */
export const AddOrUpdateContactEmail = (request: IAddOrUpdateContactEmailRequest, SessionTicket?: string): Observable<IAddOrUpdateContactEmailResponse> => {
    return ApiService.post(`${PLAYFAB_ENDPOINT}/AddOrUpdateContactEmail`, request, {
        headers: GetAuthorizationHeader(SessionTicket),
    });
}

/**
 * Reset email confirmation to verify the user
 * @param request 
 * @returns 
 */
export const ResentEmailConfirmation = (request: IAddOrUpdateContactEmailRequest, SessionTicket?: string): Observable<IAddOrUpdateContactEmailResponse> => {
    return AddOrUpdateContactEmail({
        PlayFabId: request.PlayFabId,
        EmailAddress: TEMP_EMAIL_CONFIRMATION,
    }, SessionTicket).pipe(
        delay(3000),
        switchMap((response: any) => {
            return AddOrUpdateContactEmail(request, SessionTicket);
        }),
        delay(1000),
    );
}

/**
 * Get player profile from PlayFab
 * @param request 
 * @returns 
 */
const GetPlayerProfile = (PlayFabId?: string, SessionTicket?: string): Observable<IGetPlayerProfileResponse> => {
    return ApiService.post(`${PLAYFAB_ENDPOINT}/GetPlayerProfile`, {
        PlayFabId: PlayFabId ? PlayFabId : getCookie(PLAYFAB_ID_COOKIE),
        ProfileConstraints: {
            ShowContactEmailAddresses: true,
            ShowDisplayName: true,
        }
    }, {
        headers: GetAuthorizationHeader(SessionTicket),
    }).pipe(
        map(GetResponseData),
        map((response: any) => {
            const playerProfile = response.PlayerProfile || {};
            let IsVerified = false;
            let Email = '';

            if (playerProfile.ContactEmailAddresses && playerProfile.ContactEmailAddresses?.length > 0) {
                IsVerified = (playerProfile.ContactEmailAddresses[0].VerificationStatus === 'Confirmed');
                Email = playerProfile.ContactEmailAddresses[0].EmailAddress;
            }

            return {
                ...playerProfile,
                IsVerified,
                Email,
            }
        })
    );
}

const GetUserData = (SessionTicket?: string): Observable<IGetUserDataResponse> => {
    return ApiService.post(`${PLAYFAB_ENDPOINT}/GetUserReadOnlyData`, {}, {
        headers: GetAuthorizationHeader(SessionTicket),
    }).pipe(
        map(GetResponseData),
        map((response: any) => {
            let data = { Addresses: [] }
            if (response?.Data?.addresses?.Value) {
                try {
                    data.Addresses = JSON.parse(response.Data.addresses.Value);
                } catch (error) { }
            }
            return data;
        })
    );
}

/**
 * Return the PlayerProfile and PlayerData from PlayFab
 */
export const GetFullPlayer = (PlayFabId?: string, SessionTicket?: string): Observable<IFullPlayerResponse> => {
    return forkJoin([GetPlayerProfile(PlayFabId, SessionTicket), GetUserData(SessionTicket)]).pipe(
        map((response: any) => {
            const player = response[0] as IGetPlayerProfileResponse;
            const Addresses = response[1] as IGetUserDataResponse;
            return {
                ...player,
                ...Addresses,
            }
        },
        ));
}

/**
 * Set cookies based on parameters passed, all our optional
 * @param cookies 
 */
export const SetPlayFabCookies = (cookies: {
    sessionTicket?: string, entityToken?: string,
    playerId?: string, isVerified?: boolean, rememberMe: boolean,
}) => {
    const dateOptions = cookies.rememberMe ? { maxAge: 86400 } : undefined;
    if (cookies.sessionTicket) {
        setCookie(SESSION_TICKET_COOKIE, cookies.sessionTicket, dateOptions);
    }
    if (cookies.isVerified) {
        setCookie(PLAYER_VERIFIED_COOKIE, cookies.isVerified, dateOptions);
    }
    if (cookies.playerId) {
        setCookie(PLAYFAB_ID_COOKIE, cookies.playerId, dateOptions);
    }
    if (cookies.entityToken) {
        setCookie(ENTITY_TOKEN_COOKIE, cookies.entityToken, dateOptions);
    }
}

/**
 * Logout player from PlayFab by deleting cookies
 */
export const LogoutPlayer = () => {
    return of(true).pipe(
        tap(() => {
            deleteCookie(SESSION_TICKET_COOKIE);
            deleteCookie(ENTITY_TOKEN_COOKIE);
            deleteCookie(PLAYER_VERIFIED_COOKIE);
            deleteCookie(PLAYFAB_ID_COOKIE);
        }),
        delay(1000),
    )
}

/**
 * Refresh the user auth from playfab
 * @returns 
 */
export const RefreshUserAuth = (PlayFabId?: string, SessionTicket?: string): Observable<IGetPlayerProfileResponse | undefined> => {
    try {
        SessionTicket = SessionTicket ? SessionTicket : getCookie(SESSION_TICKET_COOKIE) as string;
        PlayFabId = PlayFabId ? PlayFabId : getCookie(PLAYFAB_ID_COOKIE) as string;
        if (!SessionTicket || !PlayFabId) {
            return LogoutPlayer().pipe(
                map(() => {
                    return undefined;
                })
            )
        }
        return GetFullPlayer(PlayFabId, SessionTicket).pipe(
            catchError((error) => {
                return LogoutPlayer().pipe(
                    map(() => {
                        return undefined;
                    })
                )
            })
        )
    } catch (error) {
        return LogoutPlayer().pipe(
            map(() => {
                return undefined;
            })
        )
    }
}

/**
 * Connect or update PlayFab addresses with the wallet addresses selected by the user
 * @param wallet 
 * @returns 
 */
export const connectPlayFabToWallet = (wallet: string = ''): Observable<any> => {
    const playFabId: string = String(getCookie('PlayFabId'));
    if (!wallet || !playFabId) {
        // throw new Error('Error connecting the wallet');
        return of({
            message: 'NotConnected'
        })
    }
    const connectWallet$ = from(getWalletContextData(wallet)).pipe(
        switchMap((walletContext) => {
            return WalletApiService.getWalletConnectSignature({
                playFabId,
                wallet,
            }).pipe(
                map((messageToSign: string) => {
                    return {
                        walletContext,
                        messageToSign: messageToSign,
                    }
                })
            )
        }),
        switchMap((response: any) => {
            return signMessage$(response.walletContext.signer, response.messageToSign);
        }),
        switchMap((signedMessage: string) => {
            return WalletApiService.setWalletConnect({
                playFabId,
                wallet,
                signedMessage,
            });
        }),
    );


    return from(GetUserData()).pipe(
        mergeMap((response) => {
            const playerAddresses = response.Addresses;
            const isAddressConnected = playerAddresses.find((address) => {
                return (address?.toLowerCase() === wallet?.toLowerCase());
            });

            return iif(() => !!isAddressConnected, of({
                message: 'Connected'
            }), connectWallet$)
        }),
        map((response) => {
            return response;
        })
    )
}

// update the display name of the playfab user
export const UpdateDisplayName = (DisplayName: string, retryPostfix?: string, SessionTicket?: string) => {
    return ApiService.post(`${PLAYFAB_ENDPOINT}/UpdateUserTitleDisplayName`, {
        DisplayName,
    }, {
        headers: GetAuthorizationHeader(SessionTicket),
    }).pipe(
        map(() => {
            return DisplayName;
        }),
        catchError((error) => {
            if (error.error === "NameNotAvailable") {
                return UpdateDisplayName(`${DisplayName}${retryPostfix}`, SessionTicket).pipe(
                    map(() => {
                        return `${DisplayName}${retryPostfix}`
                    }),
                )
            } else {
                return of('');
            }
        })
    )
}

// upgrade an anoninous user to have email, password and username
export const AddUsernamePassword = (request: { playerId: string, email: string, password: string, username: string }) => {
    return ApiService.post(`${PLAYFAB_ENDPOINT}/AddUsernamePassword`, {
        Email: request.email,
        Password: request.password,
        Username: request.username,
    }, {
        headers: GetAuthorizationHeader(),
    }).pipe(
        switchMap((response) => {
            return UpdateDisplayName(request.username, EPIC_GAMES_POSTFIX).pipe(
                map((displayNameResponse: string) => {
                    return {
                        ...request,
                        displayName: displayNameResponse,
                    }
                })
            )
        }),
        switchMap((response) => {
            return ResentEmailConfirmation({
                PlayFabId: request.playerId,
                EmailAddress: request.email,
            }).pipe(
                map((confirmationResponse) => {
                    return response;
                })
            )
        })
    )
}


// login with epic
export const loginWithEpic = (code: string) => {
    return LogoutPlayer().pipe(
        switchMap(() => {
            return ApiService.post(`${ApiService.API_GATEWAY_ENDPOINT}/1/api/player/epic/oauth/login`, {
                code,   
            }).pipe(
                map((epicAccountResponse) => {
                    return {
                        epic: epicAccountResponse
                    }
                })
            )
        }),
        switchMap((epicResponse) => {
            return ApiService.post(`${PLAYFAB_ENDPOINT}/LoginWithOpenIdConnect`, {
                ConnectionId: `EOS`,
                IdToken: epicResponse.epic.auth.access_token,
                TitleId: PLAYFAB_TITLE_ID,
                CreateAccount: true
            }).pipe(
                map(GetResponseData),
                map((response) => {
                    return {
                        epic: epicResponse.epic,
                        playFab: {
                            auth: response,
                        }
                    }
                })
            )
        }),
        tap((response: { epic: any, playFab: { auth: any } }) => {
            const loginResponse = response.playFab.auth;
            SetPlayFabCookies({
                entityToken: JSON.stringify(loginResponse.EntityToken),
                sessionTicket: loginResponse.SessionTicket,
                playerId: loginResponse.PlayFabId,
                isVerified: true,
                rememberMe: false,
            })
        }),
        delay(3000),
        switchMap((epicPlayFabResponse: { epic: any, playFab: { auth: any } }) => {
            const loginResponse = epicPlayFabResponse.playFab.auth;
            return GetFullPlayer(loginResponse.PlayFabId, loginResponse.SessionTicket).pipe(
                switchMap((profileResponse: IFullPlayerResponse) => {
                    SetPlayFabCookies({
                        rememberMe: false,
                        entityToken: JSON.stringify(loginResponse.EntityToken),
                        sessionTicket: loginResponse.SessionTicket,
                        playerId: loginResponse.PlayFabId,
                        isVerified: profileResponse.IsVerified,
                    });

                    let finalResult: any = {
                        ...loginResponse,
                        ...profileResponse
                    };

                    let updateUserApi$ = of(finalResult);
                    if (!profileResponse?.DisplayName) {
                        updateUserApi$ = UpdateDisplayName(epicPlayFabResponse.epic.account.displayName, EPIC_GAMES_POSTFIX, loginResponse.SessionTicket).pipe(
                            map((displayNameResponse: string) => {
                                finalResult['DisplayName'] = displayNameResponse;
                                return finalResult;
                            }),
                        )
                    }

                    return updateUserApi$;
                }),
            )
        }),
    )
}

/***
 * SERVER SIDE USER AUTH FOR PLAYFAB
 * https://nextjs.org/docs/pages/api-reference/functions/get-server-side-props
 */
export interface IUserAuthServerSide {
    props: {
        query: {[key: string]: string | number};
        player: IGetPlayerProfileResponse | undefined;
    } 
}

export interface IUserAuthServerSideRedirect {
    redirect: {
        permanent: boolean;
        destination: string;
    }
}

export const UserAuthServerSide = async(context): Promise<IUserAuthServerSide | IUserAuthServerSideRedirect> => {
    const { req, res, params, query, resolvedUrl } = context;
    let player: IGetPlayerProfileResponse | undefined;
    
    // full url
    const url = new URL(`${process.env.NEXT_PUBLIC_BASE_URL}${resolvedUrl}`);
    //console.info(`Resolve URL: ${url}`);
    // query param url.searchParams.get("page")
    // console.info(`Query params: ${url.searchParams}`);
    // console.info(`Dynamic route params: ${params}`);

    const SessionTicket: string = req.cookies['SessionTicket'] || '';
    const PlayFabId: string = req.cookies['PlayFabId'] || '';

    try {
        if (SessionTicket && PlayFabId) {
            player = await RefreshUserAuth(PlayFabId, SessionTicket).toPromise();
        }
    } catch (error) {
        console.info(`User not authenticated`);
        console.info(error);
    }

    let redirectUrl: IUserAuthServerSideRedirect = {
        redirect: {
            permanent: false,
            destination: '',
        }
    }
    const isWalletPage = resolvedUrl.indexOf('/wallet') !== -1 ? true: false;
    const isProfilePage = resolvedUrl.indexOf('/profile') !== -1 ? true: false;
    if (!player) {
        // user not logged in, should be redirected to sign-in page
        redirectUrl.redirect.destination = `/sign-in&redirectUrl=${encodeURIComponent(resolvedUrl)}`;
    } else if (!player.Email) {
        // user has not email set
        redirectUrl.redirect.destination = `/connect?context=email&redirectUrl=${encodeURIComponent(resolvedUrl)}`;
    } else if (!player.IsVerified && isWalletPage) {
        // user has not email set
        redirectUrl.redirect.destination = `/email-verification?context=request&redirectUrl=${encodeURIComponent(resolvedUrl)}`;
    } else if (player.Addresses.length === 0 && !isProfilePage) {
        redirectUrl.redirect.destination = `/connect?context=general&redirectUrl=${encodeURIComponent(resolvedUrl)}`;
    }
    

    if (redirectUrl.redirect.destination) {
        // console.info(`Redirected to: ${redirectUrl.redirect.destination}`)
        return redirectUrl;
    } else {
        return {
            props: {
                query: query ? query: undefined,
                player
            },
        }
    }
} 

