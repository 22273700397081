import { filter, from, Observable } from "rxjs";

/**
 * Ask user Metamask to sign the transaction
 * @param signer 
 * @param messageToSign 
 * @returns message signed
 */
 export const signMessage$ = (signer, messageToSign: string): Observable<string> => {
    return from(signer.signMessage(messageToSign)).pipe(
        filter((signedMessage) => {
            return (signedMessage === undefined || signedMessage === '') ? false: true;
        })
    ) as Observable<string>;
}