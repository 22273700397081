import { ethers } from "ethers";
import { IWalletContext } from "../../providers/UserContext";
import { isWeb3Available, validateAddress } from "../utils";
import { metaNanoEnv, } from "../../chainConstants";
import { polygonConf } from "../../constants/chainConstants";

/**
 * Request the user to connect the wallet via Metamask
 * @returns 
 */
export const connect = async () => {
    if (!isWeb3Available()) {
        return null;
    } else {
        try {
            const { ethereum } = window;
            const accounts = await ethereum.request({ method: "eth_requestAccounts" });
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const chainId = await ethereum.request({
                method: "net_version",
            });

            // web3Details
            return {
                account: accounts[0],
                provider: provider,
                signer: signer,
                chainId: chainId
            }
        } catch (e) {
            // console.error('cannot connect to web3: ', e);
        }
    }
    return null;
}

/**
 * If the wallet is already connected, this prepare all the data needed across the entire application to run
 * in terms of web3
 * @param selectedAccount 
 * @returns 
 */
export const getWalletContextData = async(selectedAccount: string = ''): Promise<IWalletContext> => {
    let walletData: IWalletContext = {
        account: selectedAccount,
        isValidChain: false,
        isAuthorized: false,
        isValidAddress: validateAddress(selectedAccount),
        isOwner: false,
        isPolygon: false,
    };
    
    try {
        const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        walletData.chainId = await ethereum.request({ method: "net_version", });
        walletData.isValidChain = (parseInt(walletData.chainId as any) === parseInt(metaNanoEnv.targetChainId as any) || parseInt(walletData.chainId as any) === parseInt(process.env.NEXT_PUBLIC_WANCHAIN_CHAIN as any));
        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();
        walletData.isOwner = !selectedAccount || (selectedAccount.toLowerCase() === signerAddress.toLowerCase());
        walletData.isAuthorized = true;
        walletData.signer = signer;
        walletData.provider = provider;
        walletData.isPolygon = (parseInt(walletData.chainId as any) === parseInt(metaNanoEnv.targetChainId as any)) ? true: false;
        
        if (walletData.isOwner && walletData.isValidChain) {
            const balance = await provider.getBalance(signerAddress);
            const balanceInEth = ethers.utils.formatEther(balance);
            walletData = Object.assign({}, walletData, {
                account: signerAddress,
                provider,
                signer,
                balance: balanceInEth,
                isOwner: true,
                isValidAddress: validateAddress(signerAddress),
            });
        }
    } catch (e) {
        // console.error('Error connecting the wallet', e);
    }

    return walletData;
}

export const switchToPolygon = async (user) => {
    let method = "wallet_addEthereumChain";
    if (user?.wallet.provider && typeof window !== 'undefined' && typeof window.ethereum !== 'undefined') {
        window.ethereum.request({
            "id": 1,
            "jsonrpc": "2.0",
            "method": method,
            "params": [polygonConf]
        });
    } else {
        console.error('Fail network switching')
    }
}

