export interface INano {
    nano: string;
    slug: string;
    name: string;
    description: string;
    video?: boolean;
    variants: Array<string>;
    ai: {
        character: string;
        scene: string;
        enabled: boolean;
    }
}

export const nanos: Array<INano> = [
    {
        "nano": "Magician",
        "slug": "nerlin",
        "name": "Nerlin",
        "description": "As the original inventor of magic, Nerlin is still trying to decode all its secrets, most importantly, how to make cats land on their backs.",
        "variants": [
            "nanos/variants/rectangle/1_prime/0_1.webp",
            "nanos/variants/rectangle/2_wild/0_2.webp",
            "nanos/variants/rectangle/3_elite/0_3.webp",
            "nanos/variants/rectangle/4_gold/0_4.webp",
            "nanos/variants/rectangle/5_daemon/0_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/nerlin",
            "scene": "workspaces/meta_nanos/scenes/nerlin_meets_player",
            "enabled": true,
        }
    },
    {
        "nano": "Witch",
        "slug": "shayla",
        "name": "Shayla",
        "description": "Not only is Shayla a complete nerd, but she is also a very talented enchantress and can turn you into a frog, especially if you make a grammar mistake.",
        "variants": [
            "nanos/variants/rectangle/1_prime/1_1.webp",
            "nanos/variants/rectangle/2_wild/1_2.webp",
            "nanos/variants/rectangle/3_elite/1_3.webp",
            "nanos/variants/rectangle/4_gold/1_4.webp",
            "nanos/variants/rectangle/5_daemon/1_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/shayla",
            "scene": "workspaces/meta_nanos/scenes/shayla_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Goblin",
        "slug": "green_eugene",
        "name": "Green Eugene",
        "description": "His two big talents are stealing and explosives. Sometimes stealing with explosives. And sometimes also stealing explosives.",
        "variants": [
            "nanos/variants/rectangle/1_prime/2_1.webp",
            "nanos/variants/rectangle/2_wild/2_2.webp",
            "nanos/variants/rectangle/3_elite/2_3.webp",
            "nanos/variants/rectangle/4_gold/2_4.webp",
            "nanos/variants/rectangle/5_daemon/2_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/green_eugene",
            "scene": "workspaces/meta_nanos/scenes/green_eugene_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Undead",
        "slug": "igor_todd",
        "name": "Igor Todd",
        "description": "Created by Dr. Frankengem, Igor Todd is always searching for the true purpose of his life, and '42' wasn't a satisfying answer for him.",
        "variants": [
            "nanos/variants/rectangle/1_prime/3_1.webp",
            "nanos/variants/rectangle/2_wild/3_2.webp",
            "nanos/variants/rectangle/3_elite/3_3.webp",
            "nanos/variants/rectangle/4_gold/3_4.webp",
            "nanos/variants/rectangle/5_daemon/3_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/",
            "scene": "workspaces/meta_nanos/scenes/igor_todd_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Elve",
        "slug": "ethenia",
        "name": "Ethenia",
        "description": "She loves all the good things in the world and the interconnectedness of everything - and Power Metal.",
        "variants": [
            "nanos/variants/rectangle/1_prime/4_1.webp",
            "nanos/variants/rectangle/2_wild/4_2.webp",
            "nanos/variants/rectangle/3_elite/4_3.webp",
            "nanos/variants/rectangle/4_gold/4_4.webp",
            "nanos/variants/rectangle/5_daemon/4_5.webp"

        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/ethenia",
            "scene": "workspaces/meta_nanos/scenes/ethenia_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Ape",
        "slug": "chappy",
        "name": "Chappy",
        "description": "He likes hanging out with friends and chillin'. A great climber with stunning reflexes. Don't snitch his banana, though. You will regret it.",
        "variants": [
            "nanos/variants/rectangle/1_prime/5_1.webp",
            "nanos/variants/rectangle/2_wild/5_2.webp",
            "nanos/variants/rectangle/3_elite/5_3.webp",
            "nanos/variants/rectangle/4_gold/5_4.webp",
            "nanos/variants/rectangle/5_daemon/5_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/chappy",
            "scene": "workspaces/meta_nanos/scenes/chappy_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Ice Princess",
        "slug": "aurelia",
        "name": "Aurelia",
        "description": "A body as cold as her heart. Strong, fierce & ice cold! Do not mess with her, she will show no mercy.",
        "variants": [
            "nanos/variants/rectangle/1_prime/6_1.webp",
            "nanos/variants/rectangle/2_wild/6_2.webp",
            "nanos/variants/rectangle/3_elite/6_3.webp",
            "nanos/variants/rectangle/4_gold/6_4.webp",
            "nanos/variants/rectangle/5_daemon/6_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/aurelia",
            "scene": "workspaces/meta_nanos/scenes/aurelia_meets_owner",
            "enabled": false,
        }
    },
    {
        "nano": "orc",
        "slug": "horc_hogan",
        "name": "Horc Hogan",
        "description": "Controlling his anger is not one of his strengths. He has an impressive collection of teeth.",
        "variants": [
            "nanos/variants/rectangle/1_prime/7_1.webp",
            "nanos/variants/rectangle/2_wild/7_2.webp",
            "nanos/variants/rectangle/3_elite/7_3.webp",
            "nanos/variants/rectangle/4_gold/7_4.webp",
            "nanos/variants/rectangle/5_daemon/7_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/horc_hogan",
            "scene": "workspaces/meta_nanos/scenes/horc_hogan_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Enchantress",
        "slug": "illyria",
        "name": "Illyria",
        "description": "Although she is a devoted follower of Donwen, she isn't really a nun figure and has already turned many NANOs into toads or socks.",
        "variants": [
            "nanos/variants/rectangle/1_prime/8_1.webp",
            "nanos/variants/rectangle/2_wild/8_2.webp",
            "nanos/variants/rectangle/3_elite/8_3.webp",
            "nanos/variants/rectangle/4_gold/8_4.webp",
            "nanos/variants/rectangle/5_daemon/8_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/illyria",
            "scene": "workspaces/meta_nanos/scenes/illyria_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Alien",
        "slug": "bob",
        "name": "Bob",
        "description": "He claims to come from outside the NANOverse, and his crippling addiction to green tea makes that very believable.",
        "variants": [
            "nanos/variants/rectangle/1_prime/9_1.webp",
            "nanos/variants/rectangle/2_wild/9_2.webp",
            "nanos/variants/rectangle/3_elite/9_3.webp",
            "nanos/variants/rectangle/4_gold/9_4.webp",
            "nanos/variants/rectangle/5_daemon/9_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/bob",
            "scene": "workspaces/meta_nanos/scenes/bob_meets_owner",
            "enabled": false,
        }
    },
    {
        "nano": "Skeleton",
        "slug": "indiana_bones",
        "name": "Indiana Bones",
        "description": "No skin, no sense of taste, no 3D vision. That didn't stop him from being a fashion designer, though.",
        "variants": [
            "nanos/variants/rectangle/1_prime/10_1.webp",
            "nanos/variants/rectangle/2_wild/10_2.webp",
            "nanos/variants/rectangle/3_elite/10_3.webp",
            "nanos/variants/rectangle/4_gold/10_4.webp",
            "nanos/variants/rectangle/5_daemon/10_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/indiana_bones",
            "scene": "workspaces/meta_nanos/scenes/indiana_bones_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Fire demon",
        "slug": "sparky",
        "name": "Sparky",
        "description": "He loves everything that is hot: chili, hot pants, Hot Rods, deserts. Ice cream is his arch nemesis, though.",
        "variants": [
            "nanos/variants/rectangle/1_prime/11_1.webp",
            "nanos/variants/rectangle/2_wild/11_2.webp",
            "nanos/variants/rectangle/3_elite/11_3.webp",
            "nanos/variants/rectangle/4_gold/11_4.webp",
            "nanos/variants/rectangle/5_daemon/11_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/",
            "scene": "workspaces/meta_nanos/scenes/sparky_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "knight",
        "slug": "sir_render_1st",
        "name": "Sir Render 1st",
        "description": "Sir Render 1st was the first to enter the Caverns of Truth, the first to face the Creator, but will not surrender first in a fight.",
        "variants": [
            "nanos/variants/rectangle/1_prime/12_1.webp",
            "nanos/variants/rectangle/2_wild/12_2.webp",
            "nanos/variants/rectangle/3_elite/12_3.webp",
            "nanos/variants/rectangle/4_gold/12_4.webp",
            "nanos/variants/rectangle/5_daemon/12_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/sir_render_1st",
            "scene": "workspaces/meta_nanos/scenes/sir_render_1st_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Dwarf",
        "slug": "thorix",
        "name": "Thorix",
        "description": "Two things you should never - never! - do: Taunt Thorix for his height and enter a drinking competition against him.",
        "variants": [
            "nanos/variants/rectangle/1_prime/13_1.webp",
            "nanos/variants/rectangle/2_wild/13_2.webp",
            "nanos/variants/rectangle/3_elite/13_3.webp",
            "nanos/variants/rectangle/4_gold/13_4.webp",
            "nanos/variants/rectangle/5_daemon/13_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/thorix",
            "scene": "workspaces/meta_nanos/scenes/thorix_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Golen",
        "slug": "jay_the_don_rockson",
        "name": "Jay the Don Rockson",
        "description": "His acting career is over, but the Paparazzi still follow him everywhere he goes.",
        "variants": [
            "nanos/variants/rectangle/1_prime/14_1.webp",
            "nanos/variants/rectangle/2_wild/14_2.webp",
            "nanos/variants/rectangle/3_elite/14_3.webp",
            "nanos/variants/rectangle/4_gold/14_4.webp",
            "nanos/variants/rectangle/5_daemon/14_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/jay_the_don_rockson",
            "scene": "workspaces/meta_nanos/scenes/jay_the_don_rockson_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Puppet",
        "slug": "socky_balboa",
        "name": "Socky Balboa",
        "description": "Socky has amassed a lot of wealth totally legally. Never mention the word 'Mafia' in his presence, though!",
        "variants": [
            "nanos/variants/rectangle/1_prime/15_1.webp",
            "nanos/variants/rectangle/2_wild/15_2.webp",
            "nanos/variants/rectangle/3_elite/15_3.webp",
            "nanos/variants/rectangle/4_gold/15_4.webp",
            "nanos/variants/rectangle/5_daemon/15_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/socky_balboa",
            "scene": "workspaces/meta_nanos/scenes/socky_balboa_greets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Samurai",
        "slug": "sam_urai",
        "name": "Sam Urai",
        "description": "He is equally as sophisticated in his mastery of the katana as the calligraphy brush.",
        "variants": [
            "nanos/variants/rectangle/1_prime/16_1.webp",
            "nanos/variants/rectangle/2_wild/16_2.webp",
            "nanos/variants/rectangle/3_elite/16_3.webp",
            "nanos/variants/rectangle/4_gold/16_4.webp",
            "nanos/variants/rectangle/5_daemon/16_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/sam_urai",
            "scene": "workspaces/meta_nanos/scenes/sam_urai_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Space marine",
        "slug": "star_walker",
        "name": "Star Walker",
        "description": "...time travel. Armed with a multi-melta, he is as powerful as some armies, and he is rumored to...",
        "variants": [
            "nanos/variants/rectangle/1_prime/17_1.webp",
            "nanos/variants/rectangle/2_wild/17_2.webp",
            "nanos/variants/rectangle/3_elite/17_3.webp",
            "nanos/variants/rectangle/4_gold/17_4.webp",
            "nanos/variants/rectangle/5_daemon/17_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/",
            "scene": "workspaces/meta_nanos/scenes/star_walker_greets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Teddy",
        "slug": "jack_bearow",
        "name": "Jack Bearow",
        "description": "Jack loves cuddling. If you ruffle his fur even once, he will follow you forever. But a bear is and is a bear, strong and dangerous.",
        "variants": [
            "nanos/variants/rectangle/1_prime/18_1.webp",
            "nanos/variants/rectangle/2_wild/18_2.webp",
            "nanos/variants/rectangle/3_elite/18_3.webp",
            "nanos/variants/rectangle/4_gold/18_4.webp",
            "nanos/variants/rectangle/5_daemon/18_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/star_walker",
            "scene": "workspaces/meta_nanos/scenes/jack_bearow_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Panda",
        "slug": "pandalorian",
        "name": "Pandalorian",
        "description": "He seems out of shape but in fact is a specialist in martial arts. Now and then he needs a new bamboo stick, because he ate the old one.",
        "variants": [
            "nanos/variants/rectangle/1_prime/19_1.webp",
            "nanos/variants/rectangle/2_wild/19_2.webp",
            "nanos/variants/rectangle/3_elite/19_3.webp",
            "nanos/variants/rectangle/4_gold/19_4.webp",
            "nanos/variants/rectangle/5_daemon/19_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/pandalorian",
            "scene": "workspaces/meta_nanos/scenes/pandalorian_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Bunny",
        "slug": "sergeant_hop",
        "name": "Sergeant Hop",
        "description": "His favorite movie is Rambo, his hero Arnold Schwarzenegger, and in his free time he is either in the gym or mixes protein shakes.",
        "variants": [
            "nanos/variants/rectangle/1_prime/20_1.webp",
            "nanos/variants/rectangle/2_wild/20_2.webp",
            "nanos/variants/rectangle/3_elite/20_3.webp",
            "nanos/variants/rectangle/4_gold/20_4.webp",
            "nanos/variants/rectangle/5_daemon/20_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/sergeant_hop",
            "scene": "workspaces/meta_nanos/scenes/sergeant_hop_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Valkyrie",
        "slug": "skylar",
        "name": "Skylar",
        "description": "She likes to say she has it all: super-human strength, stamina, speed, durability, reflexes - and humility.",
        "variants": [
            "nanos/variants/rectangle/1_prime/21_1.webp",
            "nanos/variants/rectangle/2_wild/21_2.webp",
            "nanos/variants/rectangle/3_elite/21_3.webp",
            "nanos/variants/rectangle/4_gold/21_4.webp",
            "nanos/variants/rectangle/5_daemon/21_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/skylar",
            "scene": "workspaces/meta_nanos/scenes/skylar_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Tree",
        "slug": "morgan_treeman",
        "name": "Morgan Treeman",
        "description": "If you have ever wondered how someone being on the thin line between genius and weird would be - you don't have to wonder anymore.",
        "variants": [
            "nanos/variants/rectangle/1_prime/22_1.webp",
            "nanos/variants/rectangle/2_wild/22_2.webp",
            "nanos/variants/rectangle/3_elite/22_3.webp",
            "nanos/variants/rectangle/4_gold/22_4.webp",
            "nanos/variants/rectangle/5_daemon/22_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/morgan_treeman",
            "scene": "workspaces/meta_nanos/scenes/morgan_treeman_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Robot",
        "slug": "metanator",
        "name": "Metanator",
        "description": "01001001 00100000 01011111 01100001 01101101 01011111 00100000 01100001 00100000 01001110 01000001 01001110 01001111 00100001",
        "variants": [
            "nanos/variants/rectangle/1_prime/23_1.webp",
            "nanos/variants/rectangle/2_wild/23_2.webp",
            "nanos/variants/rectangle/3_elite/23_3.webp",
            "nanos/variants/rectangle/4_gold/23_4.webp",
            "nanos/variants/rectangle/5_daemon/23_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/metanator",
            "scene": "workspaces/meta_nanos/scenes/metanator_meets_player",
            "enabled": false,
        }
    },
    {
        "nano": "Cactus",
        "slug": "hurt_cobain",
        "name": "Hurt Cobain",
        "description": "When my poison starts working, you won't even remember that you have ever met me. This isn't our first encounter. See?",
        "variants": [
            "nanos/variants/rectangle/1_prime/24_1.webp",
            "nanos/variants/rectangle/2_wild/24_2.webp",
            "nanos/variants/rectangle/3_elite/24_3.webp",
            "nanos/variants/rectangle/4_gold/24_4.webp",
            "nanos/variants/rectangle/5_daemon/24_5.webp"
        ],
        "ai": {
            "character": "workspaces/meta_nanos/characters/hurt_cobain",
            "scene": "workspaces/meta_nanos/scenes/hurt_cobain_greets_player",
            "enabled": false,
        }
    },
];

// return the nano variation by index number
export const getNanoVariationTypeByIndex = (index: number, lowerCase = false): string => {
    let variationName = '';
    switch (index) {
        case 0:
            variationName = 'Prime';
            break;
        case 1:
            variationName = 'Wild';
            break;
        case 2:
            variationName = 'Elite';
            break;
        case 3:
            variationName = 'Gold';
            break;
        case 4:
            variationName = 'Daemon';
            break;
        default:
            variationName = 'Prime';
            break;
    }

    return (lowerCase === true) ? variationName.toLowerCase() : variationName;
}

// return the nano variation by nam
export const getNanoVariationIndexByName = (variant: string): number => {
    switch (variant.toLocaleLowerCase()) {
        case 'wild':
            return 1;
        case 'elite':
            return 2;
        case 'gold':
            return 3;
        case 'daemon':
            case 'deamon':
        return 4;

        default:
            return 0;
    }
}

/**
 * Get the info of a power gem
 * @param name 
 * @returns 
 */
export const getPowerGemInfo = (name: string): { time: string, value: string } => {
    switch (name) {
        case 'ruby':
            return {
                time: `3 seconds`,
                value: 'Attack another NANO -45 speed',
            }
        case 'sapphire':
            return {
                time: `3 seconds`,
                value: 'Attack another NANO -35 speed',
            }
        case 'onyx':
            return {
                time: `3 seconds`,
                value: 'Attack another NANO -35 speed',
            }
        case 'diamond':
            return {
                time: `5 seconds`,
                value: 'Attack another NANO -40 speed',
            }
        case 'cryptonite':
            return {
                time: `3 seconds`,
                value: 'Give your NANO +30 speed',
            }
        case 'moonstone':
            return {
                time: `3 seconds`,
                value: 'Give your NANO +35 speed',
            }
        case 'ethernite':
            return {
                time: `3 seconds`,
                value: 'Give your NANO +40 speed',
            }
        case 'herostone':
            return {
                time: `4 seconds`,
                value: 'Give your NANO +45 speed',
            }

        default:
            return {
                time: '',
                value: '',
            }
    }
}