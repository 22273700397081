import { SvgIcon } from "@mui/material";

const WindowsIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fillRule="evenodd" clipRule="evenodd" d="m.834 3.43 7.492-1.018.004 7.204-7.49.043-.006-6.23Zm7.489 7.017.005 7.21L.84 16.631v-6.233l7.483.049Zm.908-8.168L19.165.833v8.692l-9.934.078V2.28Zm9.936 8.235-.002 8.653-9.934-1.398-.014-7.27 9.95.015Z" /></svg>
        </SvgIcon>
    );
}

export default WindowsIcon;