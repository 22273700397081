import Script from "next/script";

const StructuredData = ({ data, isStringify, id }) => {
  let schemaJson = data;
  if (!isStringify) {
    schemaJson = JSON.stringify(data);
  }
  
  return (
      <Script
        id={id}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: schemaJson }}
      />
  );
}

export default StructuredData;