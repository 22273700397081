import { Alert, Snackbar } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AlertContext, IAlertTypes } from '../../providers/AlertContext';

const UIAlert = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity]: any = useState('success');
  const [autoHideDuration, setAutoHideDuration] = useState(600);

  const { state, dispatch }: any = useContext(AlertContext);

  useEffect(() => {
    switch (state?.type) {
      case IAlertTypes.SHOW_ALERT:
        setMessage(state.message);
        setSeverity(state.severity || 'success');
        setAutoHideDuration(state.autoHideDuration || 6000);
        setOpen(true);
        break;
      default:
        break;
    }
  }, [state]);

  const handleClose = () => {
    setOpen(false);
    dispatch({ type: IAlertTypes.HIDE_ALERT });
    setMessage('');
    setAutoHideDuration(6000);
  }

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}
      anchorOrigin={{ 'vertical': 'bottom', horizontal: 'right' }}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        <span dangerouslySetInnerHTML={{ __html: message }}></span>
      </Alert>
    </Snackbar>
  )
}

export default UIAlert;
