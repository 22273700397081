import { map, Observable } from 'rxjs';
import { ApiService } from './api';

export interface ICountry {
    id: string;
    code: string;
    iso2: string;
    label: string;
}

const getCountries = (): Observable<Array<ICountry>> => {
    // `https://restcountries.com/v3.1/all`
    return ApiService.get(`${process.env.NEXT_PUBLIC_BASE_URL}/api/countries`).pipe(
        map((response) => {
            let countries = response.map((country) => {
                return {
                    id: country.cca3,
                    code: country.cca3,
                    label: country.name.common,
                }
            });
            countries = countries.sort((a: any, b: any) => {
                return (a.name > b.name) ? 1 : -1;
            });

            return countries;
        })
    );
}

// Utility function to load a javascript url
const loadJS = (scriptUrl: string, dataAttributes = {}, onLoadCallback?, onErrorCallback?) => {
    if (!scriptUrl) { return };
    
    let scriptEL = document.createElement("script");
    scriptEL.setAttribute("src", scriptUrl);
    scriptEL.setAttribute("type", "text/javascript");
    scriptEL.setAttribute("async", 'true');
    for (let key in dataAttributes) { 
        const value = dataAttributes[key];
        scriptEL.setAttribute(key, value);
    }
    document.body.appendChild(scriptEL);

    if (onLoadCallback) {
        scriptEL.addEventListener("load", onLoadCallback);
    }
    if (onErrorCallback) {
        scriptEL.addEventListener("error", onErrorCallback);
    }
}

/**
 * Return the nano slug from the nano structure return from the backend
 * @param nano 
 * @returns 
 */
const getNanoSlug = (nano) => {
    const slug = nano?.metadata?.data?.type?.name ? nano.metadata.data.type.name.toLowerCase() : '';
    return slug.replaceAll(' ', '_');
}

export const MetadataApiService = {
    getNanoSlug,
    getCountries,
    loadJS,
}
