import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://7ebbe979b4794f089732021f1594ea73@o4504044563267584.ingest.sentry.io/4504044674940928',
  tracesSampleRate: 1.0,
  enabled: process.env.NEXT_PUBLIC_APP_ENV === 'production' ?  true: false,
  environment: process.env.NEXT_PUBLIC_APP_ENV,
});
