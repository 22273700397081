import { SvgIcon } from "@mui/material";

const ShowRoomIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M20.306 6.357 12.762 2v3.968l4.096 2.383 3.448-1.994Zm-9.06-.383L7.2 8.326 3.757 6.34l7.49-4.333v3.969ZM6.443 9.646 3 7.652v8.696l3.442-1.987V9.645ZM12.762 22v-3.968l4.084-2.37 3.448 1.987L12.762 22Zm-1.515-.006-7.49-4.333 3.448-1.987 4.042 2.352v3.968Zm6.338-7.663V9.68l3.448-1.993v8.636l-3.448-1.993Z" /></svg>
        </SvgIcon>
    );
}

export default ShowRoomIcon;