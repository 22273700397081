import { SvgIcon } from "@mui/material";

const DiscordIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.536 6.798C17.07 4.948 14.724 5 14.724 5l-.24.274c2.911.89 4.264 2.175 4.264 2.175a13.95 13.95 0 0 0-5.154-1.644 14.492 14.492 0 0 0-3.459.034c-.103 0-.188.018-.291.034-.6.052-2.056.274-3.888 1.08-.633.291-1.01.496-1.01.496S6.367 6.096 9.45 5.206L9.28 5S6.932 4.95 4.465 6.798c0 0-2.466 4.47-2.466 9.982 0 0 1.439 2.483 5.223 2.604 0 0 .633-.77 1.147-1.421-2.175-.651-2.996-2.02-2.996-2.02s.17.119.48.29a.234.234 0 0 0 .069.052c.051.034.103.052.154.086.428.24.856.428 1.25.582.703.274 1.542.547 2.517.736 1.284.24 2.79.325 4.435.017a11.27 11.27 0 0 0 2.483-.736 9.76 9.76 0 0 0 1.969-1.01s-.856 1.405-3.1 2.038c.514.65 1.13 1.387 1.13 1.387 3.784-.12 5.239-2.603 5.239-2.603.001-5.515-2.464-9.984-2.464-9.984ZM8.8 15.188c-.959 0-1.747-.855-1.747-1.9s.77-1.901 1.747-1.901c.976 0 1.764.855 1.746 1.9 0 1.046-.771 1.902-1.746 1.902Zm6.25 0c-.96 0-1.747-.855-1.747-1.9s.77-1.901 1.746-1.901c.977 0 1.747.855 1.747 1.9 0 1.046-.77 1.902-1.747 1.902Z"/></svg>
        </SvgIcon>
    );
}

export default DiscordIcon;