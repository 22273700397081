import DeviceDetector from "device-detector-js";


/**
 * Return the download url and the device type
 * @returns 
 */
export const getDownloadGameInfo = (): { url: string, device: string} => {
    if (typeof window !== 'undefined') {
        const deviceDetector = new DeviceDetector();
        const result = deviceDetector.parse(window.navigator.userAgent);
        if (result.os?.name.toLocaleLowerCase() === 'mac') {
            return {
                url: `${process.env.NEXT_PUBLIC_DOWNLOAD_MAC_GAME_URL}?v=${Math.random()}`,
                device: 'mac',
            }
        }
        return {
            url: `${process.env.NEXT_PUBLIC_DOWNLOAD_WINDOW_GAME_URL}?v=${Math.random()}`,
            device: 'windows'
        }
    }

    return {
        url: ``,
        device: ''
    }
}