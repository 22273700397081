import { SvgIcon } from "@mui/material";

const GitbookIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M2 6a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3h1a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6Zm14.17 13H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v12c0 .35.06.687.17 1ZM18 8v10a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1h-1ZM6 8a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8Zm2 1v2h4V9H8Zm-2 7a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Z" /></svg>,
        </SvgIcon>
    );
}

export default GitbookIcon;