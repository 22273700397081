import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, Link, Typography, } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Image from "next/image";
import { Instagram, Twitter } from '@mui/icons-material';
import DiscordIcon from '../base/icons/DiscordIcon';

const Container = styled(Box)`
    background-Color: ${props => props.theme.vars.palette.neutral['900']};
    border-top: 1px solid ${props => props.theme.vars.palette.neutral['800']};
    width: 100%;
    padding: ${props => props.theme.spacing(6)};
`;

const Logo = styled(Image)`
    cursor: pointer !important;
    margin-top: ${props => props.theme.spacing(0.625)} !important;
`;

const SocialMediaButton = styled(IconButton)`
    width: 44px;
    height: 44px;
    color: white;
    background-color: ${props => props.theme.vars.palette.brand['100']};
    border: none;

    &.Mui-disabled {
        border: 1px solid ${props => props.theme.vars.palette.neutral['600']};
    }
` as typeof Button;

const GridContainer = styled(Grid)`
    max-width: ${props => props.theme.maxPageWidth};
    margin: ${props => props.theme.spacing(0, 'auto')};

    ${props => props.theme.breakpoints.up("md")} {
        padding-left: ${props => props.theme.spacing(10)};
        padding-right: ${props => props.theme.spacing(10)};
    }

    ${props => props.theme.breakpoints.up("lg")} {
        padding-left: ${props => props.theme.spacing(20)};
        padding-right: ${props => props.theme.spacing(20)};
    }
`;

const GridItem = styled(Grid)`
    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(0)};
    }

    &.logo {
        justify-content: left;
        margin-bottom: ${props => props.theme.spacing(8)};

        ${props => props.theme.breakpoints.up("lg")} {
            margin-bottom: ${props => props.theme.spacing(0)};
        }
    }

    &.links {
        justify-content: left;
        gap: ${props => props.theme.spacing(3)};
        margin-bottom: ${props => props.theme.spacing(8)};

        ${props => props.theme.breakpoints.up("md")} {
            margin-bottom: ${props => props.theme.spacing(0)};
        }

        ${props => props.theme.breakpoints.up("lg")} {
            justify-content: center;
        }
    }

    &.social {
        color: ${props => props.theme.vars.palette.text.primary};
        justify-content: left;
        text-align: left;

        ${props => props.theme.breakpoints.up("lg")} {
            justify-content: right;
            text-align: right;
        }

        .gridContainerSocial {
            justify-content: left;
            text-align: left;

            ${props => props.theme.breakpoints.up("lg")} {
                justify-content: right;
                text-align: right;
            }
        }
    }
`

const Footer = () => {
    return (
        <Container>
            <GridContainer container>
                <GridItem className='logo' xs={12} sm={12} md={12} lg={3}>
                    <Logo src="/images/shared/logo.webp" alt="Meta Nanos logo" height={48} width={95} />
                </GridItem>
                <GridItem container className="links" xs={12} sm={8} md={9} lg={6}>
                    <Grid xs={12} sm={5}>
                        <Link href={"/imprint"}>Imprint</Link>
                    </Grid>
                    <Grid xs={12} sm={5}>
                        <Link href={"/data-protection-declaration"}>Data Protection Declaration</Link>
                    </Grid>
                    <Grid xs={12} sm={5}>
                        <Link href={"https://docs.metananos.com"} target={"_blank"} rel="noreferrer">Gitbook Docs</Link>
                    </Grid>
                    <Grid xs={12} sm={5}>
                        <Link href={"http://forms.gle/3Ev4Eb3oM5esadmM6"} target={"_blank"} rel="noreferrer">Ambassador Program</Link>
                    </Grid>
                </GridItem>
                <GridItem container className="social" xs={12} sm={4} md={3} lg={3} gap={2}>
                    <Grid xs={12}>
                        <Typography color={'white'}>Social Media</Typography>
                    </Grid>
                    <Grid className="gridContainerSocial" container xs={12} gap={2}>
                        <Grid>
                            <SocialMediaButton aria-label="Discord button" href={"https://discord.gg/dnbRVBNfCb"} target={"_blank"}>
                                <DiscordIcon />
                            </SocialMediaButton>
                        </Grid>
                        <Grid>
                            <SocialMediaButton aria-label="Twitter button" href={"https://twitter.com/metananos"} target={"_blank"}>
                                <Twitter />
                            </SocialMediaButton>
                        </Grid>
                        <Grid>
                            <SocialMediaButton aria-label="Instagram button" href={"https://instagram.com/metananos"} target={"_blank"}>
                                <Instagram />
                            </SocialMediaButton>
                        </Grid>
                    </Grid>
                </GridItem>
            </GridContainer>
        </Container >
    );
}

export default Footer;