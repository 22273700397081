import { createContext, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { connect, getWalletContextData, } from "../blockchain/connect/web3Connect";
import { useRouter } from "next/router";
import { RefreshUserAuth, connectPlayFabToWallet, GetFullPlayer, IGetPlayerProfileResponse } from "../api/playFab";
import { of } from "rxjs";
// context data related to web3
export interface IWalletContext {
    account: string | undefined;
    signer?: any;
    provider?: any;
    chainId?: number;
    balance?: string;
    isValidAddress?: boolean;
    isValidChain: boolean;
    isAuthorized: boolean;
    isOwner: boolean;
    isPolygon: boolean;
}

export interface IPlayFabContext {
    IsVerified: boolean;
    PlayerId: string | undefined;
    PublisherId: string | undefined;
    TitleId: string | undefined;
    ContactEmailAddresses: Array<{
        EmailAddress: string,
        Name: string,
        VerificationStatus: string,
    }>;
    Email: string;
    Addresses: Array<string>;
    IsLoggedIn: boolean;
    DisplayName: string;
    FullUser: boolean;
}

// context data of web3 plus playfab
export interface IUserContext {
    wallet: IWalletContext;
    playFab: IPlayFabContext;
    isWalletLoaded: boolean;
    isPlayFabLoaded: boolean;
    isDataLoaded: boolean | undefined;
    walletConnected: boolean;
    playFabConnected: boolean;
    userConnected: boolean;
}

export const USER_ACTIONS = {
    INIT: 'INIT',
    SET_WALLET: 'SET_WALLET',
    SET_PLAYFAB: 'SET_PLAYFAB',
}

// initial state machine of the user state web3 + playfab
const USER_INITIAL_STATE: IUserContext = {
    wallet: {
        account: undefined,
        isValidChain: false,
        isAuthorized: false,
        isOwner: false,
        isValidAddress: false,
        signer: undefined,
        provider: undefined,
        chainId: undefined,
        balance: '0',
        isPolygon: false,
    },
    playFab: {
        IsVerified: false,
        PlayerId: undefined,
        PublisherId: undefined,
        TitleId: undefined,
        ContactEmailAddresses: [],
        Email: '',
        Addresses: [],
        IsLoggedIn: false,
        DisplayName: '',
        FullUser: true,
    },
    isWalletLoaded: false,
    isPlayFabLoaded: false,
    isDataLoaded: undefined,
    walletConnected: false,
    playFabConnected: false,
    userConnected: false,
}

/**
 * In order for a user to be connected, playFab addresses should contain the current address of Metamask
 * @param playFabAddresses 
 * @param wallet 
 */
const isUserConnected = (playFabAddresses: Array<string> = [], wallet: string | undefined): boolean => {
    const address = playFabAddresses.find((address: string) => {
        return (address?.toLowerCase() === wallet?.toLowerCase());
    })
    return address ? true: false;
}

const isDataLoaded = (isPlayFabLoaded: boolean | undefined, isWalletLoaded: boolean | undefined): boolean | undefined => {
    if (isPlayFabLoaded === undefined || isWalletLoaded === undefined) { return undefined;}
    return (isPlayFabLoaded && isWalletLoaded);
}

/**
 * This is the BIG STATE MACHINE TO Fully get the web3 + playfab all together
 * @param state 
 * @param action 
 * @returns 
 */
const userReducer = (state: IUserContext, action: any): IUserContext => {
    let userConnected = false;
    switch (action.type) {
        case USER_ACTIONS.SET_WALLET:
            const walletConnected = (action.account && action.isOwner && action.isValidAddress) ? true : false;
            userConnected = isUserConnected(state.playFab.Addresses, action.account);
            return Object.assign({}, state, {
                wallet: {
                    account: action.account,
                    signer: action.signer,
                    provider: action.provider,
                    isOwner: action.isOwner,
                    balance: action.balance,
                    isValidAddress: action.isValidAddress,
                    chainId: action.chainId,
                    isValidChain: action.isValidChain,
                    isAuthorized: action.isAuthorized,
                    isPolygon: action.isPolygon,
                },
                isWalletLoaded: action.isWalletLoaded || state.isWalletLoaded,
                isDataLoaded: isDataLoaded(state.isPlayFabLoaded, action.isWalletLoaded),
                walletConnected,
                userConnected,
            });
        case USER_ACTIONS.SET_PLAYFAB:
            const playFabConnected = action.PlayerId ? true : false;
            userConnected = isUserConnected(action.Addresses, state.wallet.account);
            return Object.assign({}, state, {
                playFab: {
                    PlayerId: action?.PlayerId,
                    IsVerified: action?.IsVerified,
                    PublisherId: action?.PublisherId,
                    TitleId: action?.TitleId,
                    ContactEmailAddresses: action?.ContactEmailAddresses || [],
                    Email: action?.Email,
                    Addresses: action?.Addresses || [],
                    IsLoggedIn: playFabConnected,
                    DisplayName: action?.DisplayName,
                    FullUser: action?.Email ? true: false,
                },
                isPlayFabLoaded: action.isPlayFabLoaded,
                isDataLoaded: isDataLoaded(action.isPlayFabLoaded, state.isWalletLoaded),
                playFabConnected,
                userConnected,
            });

        default:
            return state;
    }
}

const UserContext: any = createContext<IUserContext>(USER_INITIAL_STATE);

const UserContextProvider = (props) => {

    const router = useRouter();
    const [user, dispatchUser] = useReducer(userReducer, USER_INITIAL_STATE);
    const firstRender = useRef(true);

    /**
     * Connect to the wallet, prompt metamask
     */
    const connectWeb3 = async () => {
        const web3Details = await connect();
        if (web3Details) {
            await setWallet();
        }
    }

    // check if play is logged in playfab
    useEffect(() => {
        let refreshPlayFabPlayer$;
        if (props?.player) {
             // if our server side send the data, we dont need to refetch the playfab data
            refreshPlayFabPlayer$ = of(props.player);
        } else {
            // refetch playfab data
            refreshPlayFabPlayer$ = RefreshUserAuth();
        }
        
        refreshPlayFabPlayer$.subscribe({
            next: (response: IGetPlayerProfileResponse | undefined) => {
                dispatchUser({
                    type: USER_ACTIONS.SET_PLAYFAB,
                    ...response,
                    isPlayFabLoaded: true,
                });
            }, 
            error: (error) => {
                dispatchUser({
                    type: USER_ACTIONS.SET_PLAYFAB,
                    isPlayFabLoaded: true,
                });
            }
        });
    }, []);


    /**
     * Set and dispatch the wallet context data
     * if empty, it will take the wallet context from metamask provider
     * @param selectedAccount 
     * @return walletContext
     */
    const setWallet = async(selectedAccount?: string) => {
        const walletContext = await getWalletContextData(selectedAccount);
        dispatchUser({
            type: USER_ACTIONS.SET_WALLET,
            ...walletContext,
            isWalletLoaded: true,
        });
        return walletContext;
    }

    const initWallet = useCallback((async() => {
        await setWallet();
    }), []);

    useEffect(() => {
        if (!firstRender.current) {return};
        firstRender.current = false;
        initWallet();
    }, [firstRender, initWallet]);

    let eventListenersLoaded;

    // If accounts are changed, then reload the page
    useEffect(() => {
        if (!router.isReady) {return};
        if (eventListenersLoaded) { return }
        eventListenersLoaded = window.ethereum?.on('accountsChanged', (accounts: Array<string> = []) => {
            let redirectUrl = window.location.href.replace(window.location.origin, '');
            if (!redirectUrl && redirectUrl.indexOf('redirectUrl') !== -1) { redirectUrl = '/connect'; }
            window.location.href = redirectUrl;
        });
        window.ethereum?.on('chainChanged', (chainId) => {
            window.location.href = '/connect';
        });

        return () => {
            // remove the 'chainChanged' and 'accountsChanged' event listeners
            window.ethereum?.removeListener('accountsChanged', (accounts: any) => { });
            window.ethereum?.removeListener('chainChanged', (accounts: any) => { });
        }
    }, [router.isReady]);

    return (
        <UserContext.Provider value={{ user, dispatchUser, connectWeb3, connectPlayFabToWallet }}>
            {props.children}
        </UserContext.Provider>
    );
}

export { UserContext, UserContextProvider };
