import { createContext, useEffect, useReducer, useState } from "react";

export const IAlertTypes = {
    SHOW_ALERT: 'SHOW_ALERT',
    HIDE_ALERT: 'HIDE_ALERT',
}

// alert context
export interface IAlertContext {
    type: string;
    isAlertOpen: boolean;
    message: string;
    severity: string;
    autoHideDuration: number;
}

// Initial alert state
const INITIAL_STATE = {
    type: IAlertTypes.HIDE_ALERT,
    isAlertOpen: false,
    message: '',
    severity: 'success',
    autoHideDuration: 6000,
}

const AlertContext: any = createContext<IAlertContext>(INITIAL_STATE);

const AlertReducer = (state, action) => {
    switch (action.type) {
        case IAlertTypes.SHOW_ALERT:
            return Object.assign({}, action, {
                isAlertOpen: true,
            });
        case IAlertTypes.HIDE_ALERT:
            return INITIAL_STATE
        default:
            return state;
    }
}

const AlertContextProvider = ({ children }: any) => {    
    const [state, dispatch] = useReducer(AlertReducer, INITIAL_STATE);

    return (
        <AlertContext.Provider value={{ state, dispatch }}>
            {children}
        </AlertContext.Provider>
    );
}

export { AlertContext, AlertContextProvider };