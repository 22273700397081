import { useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import { getCookie } from "cookies-next";

// global cookie bar in the footer
const CookieConsentBar = (props: any) => {
    // then we can set cookies for GTM, Analytics, FB and our code
    const onCookieSelected = (isAccepted: boolean = false) => {
        if (window) {
            if (window['gtag']) {
                const gtmCookies = isAccepted ? 'granted': 'denied';
                window['gtag']('consent', 'update', {
                    ad_storage: gtmCookies,
                    analytics_storage: gtmCookies,
                });
            }
            if (window['fbq']) {
                const fbCookies = isAccepted ? 'grant': 'revoke';
                window['fbq']('consent', fbCookies);
            }
        }
    }

    useEffect(() => {
        if (window && getCookie('CookieConsent') === true) {
            onCookieSelected(true);
        }
    }, []);

    return (
        <CookieConsent
            location='bottom'
            enableDeclineButton
            buttonText='I understood'
            declineButtonText='Decline'
            style={{ 
                background: '#1F1F1F', color: '#F2F2F2',
                justifyContent: 'center',
            }}
            buttonStyle={{
                background: 'linear-gradient(284.43deg, #FF00C7 -120.75%, #8F00FF 125.12%)',
                fontFamily: 'var(--poppins-font),Poppins,serif',
                borderRadius: '12px',
                fontWeight: '600',
                color: '#F2F2F2',
                minWidth: '125px',
                fontSize: '14px'
            }}
            declineButtonStyle={{
                background: 'transparent',
                border: '1px solid var(--mui-palette-brand-100)',
                fontFamily: 'var(--poppins-font),Poppins,serif',
                borderRadius: '12px',
                fontWeight: '600',
                color: '#F2F2F2',
                minWidth: '125px',
                fontSize: '14px'
            }}
            onAccept={() => { onCookieSelected(true) }}
            onDecline={() => { onCookieSelected(false) }}
        >
            We use cookies! This allows us to improve our contents and offer you an optimal result on our website.
        </CookieConsent>
    )
}

export default CookieConsentBar;